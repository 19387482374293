import React from "react";
import DeleteAccountSection from "../../sections/DeleteAccountSection/DeleteAccountSection";

export default function DeleteAccountPage() {
  return (
    <div className="mx-auto ">
      <DeleteAccountSection />
    </div>
  );
}
