import React from "react";

// Files Import
import { Link } from "react-router-dom";
import StyledMdText from "../../common/components/StyledMdText/StyledMdText";
import StyledH2Heading from "../../common/components/StyledH2Heading/StyledH2Heading";
import StyledH6Heading from "../../common/components/StyledH6Heading/StyledH6Heading";

const TermsNConditionSection = () => {
  const links = [
    { text: "Our Services", id: "our-services" },
    {
      text: "Intellectual Property Rights",
      id: "intellectual-property-rights",
    },
    { text: "User Representations", id: "user-representations" },
    { text: "User Registration", id: "user-registration" },
    { text: "Products", id: "products" },
    { text: "Purchases and Payment", id: "purchases-and-payment" },
    { text: "Subscriptions", id: "subscriptions" },
    { text: "Refunds Policy", id: "refunds-policy" },
    { text: "Prohibited Activities", id: "prohibited-activities" },
    {
      text: "User Generated Contributions",
      id: "user-generated-contributions",
    },
    { text: "Contribution License", id: "contribution-license" },
    { text: "Mobile Application License", id: "mobile-application-license" },
    { text: "Social Media", id: "social-media" },
    {
      text: "Third-Party Websites and Content",
      id: "third-party-websites-and-content",
    },
    { text: "Advertisers", id: "advertisers" },
    { text: "Services Management", id: "services-management" },
    { text: "Privacy Policy", id: "privacy-policy" },
    { text: "Copyright Infringements", id: "copyright-infringements" },
    { text: "Term and Termination", id: "term-and-termination" },
    {
      text: "Modifications and Interruptions",
      id: "modifications-and-interruptions",
    },
    { text: "Governing Law", id: "governing-law" },
    { text: "Dispute Resolution", id: "dispute-resolution" },
    { text: "Corrections", id: "corrections" },
    { text: "Disclaimer", id: "disclaimer" },
    { text: "Limitations of Liability", id: "limitations-of-liability" },
    { text: "Indemnification", id: "indemnification" },
    { text: "User Data", id: "user-data" },
    {
      text: "Electronic Communications, Transactions, and Signatures",
      id: "electronic-communications-transactions-and-signatures",
    },
    {
      text: "California Users and Residents",
      id: "california-users-and-residents",
    },
    { text: "Miscellaneous", id: "miscellaneous" },
    { text: "Contact Us", id: "contact-us" },
  ];

  const handleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="py-10 max-w-[1440px] mx-auto">
      <div className="flex pt-8 w-11/12 md:w-3/4 lg:w-[62%] mx-auto">
        <img src="/assets/policy-pets.png" alt="Policy Pets" />
      </div>
      <div className="py-4 text-center md:py-5">
        <StyledH2Heading
          fontColor={"text-black"}
          content={"Terms & Condition"}
        />
      </div>

      <div className="bg-lightGrey space-y-5 rounded-[18px] py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {/* Article 1 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"1. Agreement to Our Legal Terms"}
          />

          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="flex flex-col gap-5">
                <div>
                  We are Paw Play Love LLC (referred to as "Company," "we,"
                  "us," "our"). We are a company registered in Florida, United
                  States at 10640 Pebble Cove Lane, Boca Raton, FL 33498.
                </div>
                <div>
                  We
                  operate the{" "}
                  <Link
                    className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                    to={""}
                  >
                    Website
                  </Link>{" "}
                  (the "Site"), the mobile application{" "}
                  <Link
                    className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                    to={"#"}
                  >
                    Paw Play Love
                  </Link>{" "}
                  (the "App"), as well as any other related products and services that refer
                  or link to these legal terms (the "Legal Terms") (collectively, the "Services").
                </div>
                <div>
                  You can contact us by email at{" "}
                  <Link
                    className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                    to={"mailto:info@pawplaylove.com"}
                  >
                    info@pawplaylove.com
                  </Link>{" "}
                  or by mail  to 10640
                  Pebble cove lane, Boca Raton, FL 33498, United States.
                </div>
                <div>
                  These Legal Terms constitute a legally binding agreement
                  between you and Paw Play Love LLC concerning your access to
                  and use of the Services. By accessing the Services, you agree
                  that you have read, understood, and agree to be bound by all
                  of these Legal Terms. <span className="uppercase">If you do not agree with all of these
                    Legal Terms, you are expressly prohibited from using the
                    Services, and you must discontinue use immediately.</span>
                </div>
                <div>
                  We will provide you with prior notice of any scheduled changes to the
                  Services you are using. The modified Legal Terms will become effective
                  upon posting or notifying you by <Link
                    className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                    to={"info@pawplaylove.com"}
                  >
                    info@pawplaylove.com
                  </Link>, as stated in the
                  email message. By continuing to use the Services after the effective date
                  of any changes, you agree to be bound by the modified terms.
                </div>
                <div>
                  The Services are intended for users who are at least 13 years of age. All
                  users who are minors in the jurisdiction in which they reside (generally
                  under the age of 18) must have the permission of, and be directly
                  supervised by, their parent or guardian to use the Services. If you are a
                  minor, you must have your parent or guardian read and agree to these
                  Legal Terms prior to you using the Services.
                </div>
                <div>
                  We recommend that you print a copy of these Legal Terms for
                  your records.
                </div>
              </div>
            }
          />
        </div>

        {/* Article 2 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"2. Table of Contents"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="flex flex-col gap-2">
                {links.map((link, index) => (
                  <div key={index} className="flex items-center gap-2">
                    {index + 1}.
                    <Link
                      className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                      to={`#${link.id}`}
                      onClick={() => handleClick(link.id)}
                    >
                      {link.text}
                    </Link>
                  </div>
                ))}
              </div>
            }
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="flex flex-col gap-5">
                {/* 1. Our Services */}
                <div id="our-services" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>1. OUR SERVICES</b>
                  </div>
                  <div>
                    The information provided when using the Services is not
                    intended for distribution to or use by any person or entity
                    in any jurisdiction or country where such distribution or
                    use would be contrary to law or regulation or which would
                    subject us to any registration requirement within such
                    jurisdiction or country. Accordingly, those persons who
                    choose to access the Services from other locations do so on
                    their own initiative and are solely responsible for
                    compliance with local laws, if and to the extent local laws
                    are applicable.
                  </div>
                  <div>
                    The Services are not tailored to comply with industry-specific
                    regulations (Health Insurance Portability and Accountability Act
                    (HIPAA), Federal Information Security Management Act (FISMA),
                    etc.), so if your interactions would be subjected to such laws, you may
                    not use the Services. You may not use the Services in a way that
                    would violate the Gramm-Leach-Bliley Act (GLBA).
                  </div>
                </div>
                {/* 2. Intellectual Property Rights */}
                <div id="intellectual-property-rights" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>2. INTELLECTUAL PROPERTY RIGHTS</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <b>Our intellectual property:</b>
                      <br />
                      We are the owner or the licensee of all
                      intellectual property rights in our Services, including all source code,
                      databases, functionality, software, website designs, audio, video, text,
                      photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
                    </div>
                    <div>
                      Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.
                    </div>
                    <div>
                      The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use only.
                    </div>
                    <div>
                      <b>Your use of our Services:</b><br /> Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to:
                      <ul className="list-disc list-inside">
                        <li>access the Services; and</li>
                        <li>download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use.</li>
                      </ul>
                    </div>
                    <div>
                      Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                    </div>
                    <div>
                      If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@pawplaylove.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                    </div>
                    <div>
                      We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                    </div>
                    <div>
                      Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2 mt-8">
                  <div className="text-black">
                    <b>Your submissions and contributions:</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      Please review this section and the "PROHIBITED ACTIVITIES" section
                      carefully prior to using our Services to understand the (a) rights you
                      give us and (b) obligations you have when you post or upload any
                      content through the Services.
                    </div>
                    <div>
                      <b>Submissions:</b> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                    </div>
                    <div>
                      <b>Contributions:</b> The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, display, transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material ("Contributions"). Any Submission that is publicly posted shall also be treated as a Contribution.
                    </div>
                    <div>
                      You understand that Contributions may be viewable by other users of the Services and possibly through third-party websites.
                    </div>
                    <div>
                      <b>When you post Contributions, you grant us a license (including use of your name, trademarks, and logos):</b> By posting any Contributions, you
                      grant us an unrestricted, unlimited, irrevocable, perpetual, non
                      exclusive, transferable, royalty-free, fully-paid, worldwide right,
                      and license to: use, copy, reproduce, distribute, sell, resell, publish,
                      broadcast, retitle, store, publicly perform, publicly display, reformat,
                      translate, excerpt (in whole or in part), and exploit your Contributions
                      (including, without limitation, your image, name, and voice) for any
                      purpose, commercial, advertising, or otherwise, to prepare derivative
                      works of, or incorporate into other works, your Contributions, and
                      to sublicense the licenses granted in this section. Our use and
                      distribution may occur in any media formats and through any media
                      channels.
                    </div>
                    <div>
                      This license includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.
                    </div>
                    <div>
                      <b>You are responsible for what you post or upload:</b> By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services by linking your account through the Services to any of your social networking accounts, you:
                      <ul className="list-disc list-inside">
                        <li>confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
                        <li> to the extent permissible by applicable law, waive any and all
                          moral rights to any such Submission and/or Contribution; </li>
                        <li> warrant that any such Submission and/or Contributions are
                          original to you or that you have the necessary rights
                          and licenses to submit such Submissions and/or
                          Contributions and that you have full authority to grant us the
                          above-mentioned rights in relation to your Submissions and/or
                          Contributions; and </li>
                        <li>warrant and represent that your Submissions and/or
                          Contributions do not constitute confidential information.</li>
                      </ul>
                    </div>
                    <div>
                      You are solely responsible for your Submissions and/or Contributions and
                      you expressly agree to reimburse us for any and all losses that we may
                      suffer because of your breach of (a) this section, (b) any third partys
                      intellectual property rights, or (c) applicable law.
                    </div>
                    <div>
                      <b>We may remove or edit your Content: </b> Although we have no obligation to
                      monitor any Contributions, we shall have the right to remove or edit any
                      Contributions at any time without notice if in our reasonable opinion we
                      consider such Contributions harmful or in breach of these Legal Terms. If
                      we remove or edit any such Contributions, we may also suspend or disable
                      your account and report you to the authorities.
                    </div>
                    <div>
                      <b>Copyright infringement:</b> We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately refer to the "COPYRIGHT INFRINGEMENTS" section below.
                    </div>
                  </div>
                </div>
                {/* 3. User Representations */}
                <div id="user-representations" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>3. User Representations</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    By using the Services, you represent and warrant that: (1) all registration
                    information you submit will be true, accurate, current, and complete; (2)
                    you will maintain the accuracy of such information and promptly update
                    such registration information as necessary; (3) you have the legal
                    capacity and you agree to comply with these Legal Terms; (4) you are
                    not under the age of 13; (5) you are not a minor in the jurisdiction in
                    which you reside, or if a minor, you have received parental permission to
                    use the Services; (6) you will not access the Services through automated
                    or non-human means, whether through a bot, script or otherwise; (7) you
                    will not use the Services for any illegal or unauthorized purpose; and (8)
                    your use of the Services will not violate any applicable law or regulation.
                  </div>
                  <div>
                    If you provide any information that is untrue, inaccurate, not current, or
                    incomplete, we have the right to suspend or terminate your account and
                    refuse any and all current or future use of the Services (or any portion
                    thereof).
                  </div>
                </div>
                {/* 4. User Registration */}
                <div id="user-registration" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>4. User Registration</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      You may be required to register to use the Services. You agree to keep
                      your password confidential and will be responsible for all use of your
                      account and password. We reserve the right to remove, reclaim, or
                      change a username you select if we determine, in our sole discretion,
                      that such username is inappropriate, obscene, or otherwise
                      objectionable.
                    </div>
                  </div>
                </div>
                {/* 5. Products */}
                <div id="products" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>5. Products</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      All products are subject to availability. We reserve the right to discontinue
                      any products at any time for any reason. Prices for all products are subject
                      to change.
                    </div>
                  </div>
                </div>
                {/* 6. Purchases and Payment */}
                <div id="purchases-and-payment" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>6. Purchases and Payment</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-wrap items-center gap-2">
                      We accept the following forms of payment:
                      {/* Payment Card */}
                      <div className="flex flex-wrap gap-2">
                        <img
                          src="/assets/visa.svg"
                          alt="Visa"
                          className="w-10 h-10"
                        />
                        <img
                          src="/assets/master-card.svg"
                          alt="Mastercard"
                          className="w-10 h-10"
                        />
                        <img
                          src="/assets/amex.svg"
                          alt="American Express"
                          className="w-10 h-10"
                        />
                        <img
                          src="/assets/discover.svg"
                          alt="Discover"
                          className="w-10 h-10"
                        />
                        <img
                          src="/assets/paypal.svg"
                          alt="PayPal"
                          className="w-10 h-10"
                        />
                      </div>
                    </div>
                    <div>
                      You agree to provide current, complete, and accurate purchase and
                      account information for all purchases made via the Services. You further
                      agree to promptly update account and payment information, including
                      email address, payment method, and payment card expiration date, so
                      that we can complete your transactions and contact you as needed.
                      Sales tax will be added to the price of purchases as deemed required by
                      us. We may change prices at any time. All payments shall be in US
                      dollars.
                    </div>
                    <div>
                      You agree to pay all charges at the prices then in effect
                      for your purchases and any applicable shipping fees, and
                      you authorize us to charge your chosen payment provider
                      for any such amounts upon placing your order. We reserve
                      the right to correct any errors or mistakes in pricing,
                      even if we have already requested or received payment.
                    </div>
                    <div>
                      We reserve the right to refuse any order placed through
                      the Services. We may, in our sole discretion, limit or
                      cancel quantities purchased per person, per household, or
                      per order. These restrictions may include orders placed by
                      or under the same customer account, the same payment
                      method, and/or orders that use the same billing or
                      shipping address. We reserve the right to limit or
                      prohibit orders that, in our sole judgment, appear to be
                      placed by dealers, resellers, or distributors.
                    </div>
                  </div>
                </div>
                {/* 7. Subscriptions */}
                <div id="subscriptions" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>7. Subscriptions</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <b>Auto-Renewing Subscriptions and In-App Purchases:</b>
                    </div>
                    <div>
                      <b>1. Subscription Services:</b>
                      <div className="py-2">
                        We offer two subscription plans:
                      </div>
                      <ul>
                        <li>
                          <b>Gold Plan ($9.99/month):</b> Includes Unlimited Likes, See Who Likes
                          You, Unlimited Rewinds, 3 Free Boosts/Month, 5 Free Super Likes/Month, Top Picks, Profile Control, Control Who Sees You, and Hide
                          Ads.
                        </li>
                        <li>
                          <b>Platinum Plan ($14.99/month):</b> Includes all Gold Plan features, plus 5 Free Boosts/Month, 10 Free Super Likes/Month, and Message Before Matching.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <b>Length of Subscription:</b> Subscriptions are offered on a monthly basis.
                    </div>
                    <div>
                      <b>Auto-Renewal:</b> Subscriptions automatically renew unless canceled 24 hours before the renewal date. You can manage your subscription and auto-renewal preferences through your account settings.
                    </div>
                    <div>
                      <b>2. One-Time Purchases:</b>
                      <ul className="list-disc list-inside">
                        <li><b>Super Likes:</b></li>
                        <ul className="pl-0 space-y-2 list-disc list-inside md:pl-10">
                          <li>1 Super Like: $0.99</li>
                          <li>3 Super Likes: $1.99</li>
                          <li>5 Super Likes: $3.99</li>
                          <li>10 Super Likes: $5.99</li>
                        </ul>
                        <li><b>Boosts:</b></li>
                        <ul className="pl-0 space-y-2 list-disc list-inside md:pl-10">
                          <li>1 Boost: $1.99</li>
                          <li>3 Boosts: $2.99</li>
                          <li>5 Boosts: $4.99</li>
                          <li>10 Boosts: $9.99</li>
                        </ul>
                      </ul>
                    </div>
                    <div>
                      <b>3. Pricing Information:</b> <br /> All subscription and one-time purchase prices are displayed in USD and may vary depending on your location or applicable taxes. By subscribing or purchasing items, you consent to these charges being applied to your payment method.
                    </div>
                    <div>
                      <b>4. Cancelation and Refund Policy:</b> <br /> You can cancel subscriptions at any time via your account settings. Canceling a subscription will stop future payments but will not retroactively refund previous payments. For any inquiries about refunds, please contact us at info@pawplaylove.com.
                    </div>
                    <div>
                      <b>Functional Links:</b>
                      <ul>
                        <li>                   <Link
                          className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                          to={
                            "https://pawplaylove.com/privacy-policy"
                          }
                        >
                          Privacy Policy
                        </Link></li>
                        <li>                   <Link
                          className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                          to={
                            "https://pawplaylove.com/Terms-&-conditions"
                          }
                        >
                          Terms of Use (EULA):
                        </Link></li>
                      </ul>
                    </div>
                    <div>
                      <b>Billing and Renewal:</b><br /> Your subscription will continue and automatically renew unless canceled. You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order. The length of your billing cycle will depend on the type of subscription plan you choose when you subscribed to the Services.
                    </div>
                    <div>
                      <b>Cancellation:</b><br /> You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at <Link
                        className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                        to={
                          "info@pawplaylove.com"
                        }
                      >
                        info@pawplaylove.com.
                      </Link>
                    </div>
                    <div>
                      <b>Fee Changes:</b><br /> We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.
                    </div>
                  </div>
                </div>

                {/* 8. Refunds Policy */}
                <div id="refunds-policy" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>8. Refunds Policy</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      All sales are final and no refund will be issued.
                    </div>
                  </div>
                </div>
                {/* 9. Prohibited Activities */}
                <div id="prohibited-activities" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>9. Prohibited Activities</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      You may not access or use the Services for any purpose
                      other than that for which we make the Services available.
                      The Services may not be used in connection with any
                      commercial endeavors except those that are specifically
                      endorsed or approved by us.
                    </div>
                    <div className="flex flex-col gap-5">
                      <b>As a user of the Services, you agree not to:</b>
                      <div className="flex flex-col gap-5 md:pl-5">
                        <div>
                          <b>1.</b> Systematically retrieve data or other
                          content from the Services to create or compile,
                          directly or indirectly, a collection, compilation,
                          database, or directory without written permission from
                          us.
                        </div>
                        <div>
                          <b>2.</b> Make any unauthorized use of the Services,
                          including collecting usernames and/or email addresses
                          of users by electronic or other means for the purpose
                          of sending unsolicited email, or creating user
                          accounts by automated means or under false pretenses.
                        </div>
                        <div>
                          <b>3.</b> Use a buying agent or purchasing agent to
                          make purchases on the Services.
                        </div>
                        <div>
                          <b>4.</b> Use the Services to advertise or offer to
                          sell goods and services.
                        </div>
                        <div>
                          <b>5.</b> Circumvent, disable, or otherwise interfere
                          with security-related features of the Services,
                          including features that prevent or restrict the use or
                          copying of any Content or enforce limitations on the
                          use of the Services and/or the Content contained
                          therein.
                        </div>
                        <div>
                          <b>6.</b> Engage in unauthorized framing of or linking
                          to the Services.
                        </div>
                        <div>
                          <b>7.</b> Trick, defraud, or mislead us and other
                          users, especially in any attempt to learn sensitive
                          account information such as user passwords.
                        </div>
                        <div>
                          <b>8.</b> Make improper use of our support services or
                          submit false reports of abuse or misconduct.
                        </div>
                        <div>
                          <b>9.</b> Engage in any automated use of the system,
                          such as using scripts to send comments or messages, or
                          using any data mining, robots, or similar data
                          gathering and extraction tools.
                        </div>
                        <div>
                          <b>10.</b> Interfere with, disrupt, or create an undue
                          burden on the Services or the networks or services
                          connected to the Services.
                        </div>
                        <div>
                          <b>11.</b> Attempt to impersonate another user or
                          person or use the username of another user.
                        </div>
                        <div>
                          <b>12.</b> Sell or otherwise transfer your profile.
                        </div>
                        <div>
                          <b>13.</b> Use any information obtained from the
                          Services in order to harass, abuse, or harm another
                          person.
                        </div>
                        <div>
                          <b>14.</b> Use the Services as part of any effort to
                          compete with us or otherwise use the Services and/or
                          the Content for any revenue-generating endeavor or
                          commercial enterprise.
                        </div>
                        <div>
                          <b>15.</b> Decipher, decompile, disassemble, or
                          reverse engineer any of the software comprising or in
                          any way making up a part of the Services.
                        </div>
                        <div>
                          <b>16.</b> Attempt to bypass any measures of the
                          Services designed to prevent or restrict access to the
                          Services, or any portion of the Services.
                        </div>
                        <div>
                          <b>17.</b> Harass, annoy, intimidate, or threaten any
                          of our employees or agents engaged in providing any
                          portion of the Services to you.
                        </div>
                        <div>
                          <b>18.</b> Delete the copyright or other proprietary
                          rights notice from any Content.
                        </div>
                        <div>
                          <b>19.</b> Copy or adapt the Services’ software,
                          including but not limited to Flash, PHP, HTML,
                          JavaScript, or other code.
                        </div>
                        <div>
                          <b>20.</b> Upload or transmit (or attempt to upload or
                          to transmit) viruses, Trojan horses, or other
                          material, including excessive use of capital letters
                          and spamming (continuous posting of repetitive text),
                          that interferes with any party’s uninterrupted use and
                          enjoyment of the Services or modifies, impairs,
                          disrupts, alters, or interferes with the use,
                          features, functions, operation, or maintenance of the
                          Services.
                        </div>
                        <div>
                          <b>21.</b> Upload or transmit (or attempt to upload or
                          to transmit) any material that acts as a passive or
                          active information collection or transmission
                          mechanism, including without limitation, clear
                          graphics interchange formats (“gifs”), 1x1 pixels, web
                          bugs, cookies, or other similar devices (sometimes
                          referred to as “spyware” or “passive collection
                          mechanisms” or “pcms”).
                        </div>
                        <div>
                          <b>22.</b> Except as may be the result of standard
                          search engine or Internet browser usage, use, launch,
                          develop, or distribute any automated system, including
                          without limitation, any spider, robot, cheat utility,
                          scraper, or offline reader that accesses the Services,
                          or using or launching any unauthorized script or other
                          software.
                        </div>
                        <div>
                          <b>23.</b> Disparage, tarnish, or otherwise harm, in
                          our opinion, us and/or the Services.
                        </div>
                        <div>
                          <b>24.</b> Use the Services in a manner inconsistent
                          with any applicable laws or regulations.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 10. User Generated Contributions */}
                <div
                  id="user-generated-contributions"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>10. User Generated Contributions</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      The Services may invite you to chat, contribute to, or
                      participate in blogs, message boards, online forums, and
                      other functionality, and may provide you with the
                      opportunity to create, submit, post, display, transmit,
                      perform, publish, distribute, or broadcast content and
                      materials to us or on the Services, including but not
                      limited to text, writings, video, audio, photographs,
                      graphics, comments, suggestions, or personal information
                      or other material (collectively, "Contributions").
                      Contributions may be viewable by other users of the
                      Services and through third-party websites. As such, any
                      Contributions you transmit may be treated as
                      non-confidential and non-proprietary. When you create or
                      make available any Contributions, you thereby represent
                      and warrant that:
                    </div>
                    <div className="flex flex-col gap-5 md:pl-5">
                      <div>
                        <b>1.</b> The creation, distribution, transmission,
                        public display, or performance, and the accessing,
                        downloading, or copying of your Contributions do not and
                        will not infringe the proprietary rights, including but
                        not limited to the copyright, patent, trademark, trade
                        secret, or moral rights of any third party.
                      </div>
                      <div>
                        <b>2.</b> You are the creator and owner of or have the
                        necessary licenses, rights, consents, releases, and
                        permissions to use and to authorize us, the Services,
                        and other users of the Services to use your
                        Contributions in any manner contemplated by the Services
                        and these Legal Terms.
                      </div>
                      <div>
                        <b>3.</b> You have the written consent, release, and/or
                        permission of each and every identifiable individual
                        person in your Contributions to use the name or likeness
                        of each and every such identifiable individual person to
                        enable inclusion and use of your Contributions in any
                        manner contemplated by the Services and these Legal
                        Terms.
                      </div>
                      <div>
                        <b>4.</b> Your Contributions are not false, inaccurate,
                        or misleading.
                      </div>
                      <div>
                        <b>5.</b> Your Contributions are not unsolicited or
                        unauthorized advertising, promotional materials, pyramid
                        schemes, chain letters, spam, mass mailings, or other
                        forms of solicitation.
                      </div>
                      <div>
                        <b>6.</b> Your Contributions are not obscene, lewd,
                        lascivious, filthy, violent, harassing, libelous,
                        slanderous, or otherwise objectionable (as determined by
                        us).
                      </div>
                      <div>
                        <b>7.</b> Your Contributions do not ridicule, mock,
                        disparage, intimidate, or abuse anyone.
                      </div>
                      <div>
                        <b>8.</b> Your Contributions are not used to harass or
                        threaten (in the legal sense of those terms) any other
                        person and to promote violence against a specific person
                        or class of people.
                      </div>
                      <div>
                        <b>9.</b> Your Contributions do not violate any
                        applicable law, regulation, or rule.
                      </div>
                      <div>
                        <b>10.</b> Your Contributions do not violate the privacy
                        or publicity rights of any third party.
                      </div>
                      <div>
                        <b>11.</b> Your Contributions do not violate any
                        applicable law concerning child pornography, or
                        otherwise intended to protect the health or well-being
                        of minors.
                      </div>
                      <div>
                        <b>12.</b> Your Contributions do not include any
                        offensive comments that are connected to race, national
                        origin, gender, sexual preference, or physical handicap.
                      </div>
                      <div>
                        <b>13.</b> Your Contributions do not otherwise violate,
                        or link to material that violates, any provision of
                        these Legal Terms, or any applicable law or regulation.
                      </div>
                    </div>
                    <div>
                      Any use of the Services in violation of the foregoing violates these Legal
                      Terms and may result in, among other things, termination or suspension
                      of your rights to use the Services.
                    </div>
                  </div>
                </div>
                {/* 11. Contribution License */}
                <div id="contribution-license" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>11. Contribution License</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      By posting your Contributions to any part of the Services
                      or making Contributions accessible to the Services by
                      linking your account from the Services to any of your
                      social networking accounts, you automatically grant, and
                      you represent and warrant that you have the right to
                      grant, to us an unrestricted, unlimited, irrevocable,
                      perpetual, non-exclusive, transferable, royalty-free,
                      fully-paid, worldwide right, and license to host, use,
                      copy, reproduce, disclose, sell, resell, publish,
                      broadcast, retitle, archive, store, cache, publicly
                      perform, publicly display, reformat, translate, transmit,
                      excerpt (in whole or in part), and distribute such
                      Contributions (including, without limitation, your image
                      and voice) for any purpose, commercial, advertising, or
                      otherwise, and to prepare derivative works of, or
                      incorporate into other works, such Contributions, and
                      grant and authorize sublicenses of the foregoing. The use
                      and distribution may occur in any media formats and
                      through any media channels.
                    </div>
                    <div>
                      This license will apply to any form, media, or technology
                      now known or hereafter developed, and includes our use of
                      your name, company name, and franchise name, as
                      applicable, and any of the trademarks, service marks,
                      trade names, logos, and personal and commercial images you
                      provide. You waive all moral rights in your Contributions,
                      and you warrant that moral rights have not otherwise been
                      asserted in your Contributions.
                    </div>
                    <div>
                      We do not assert any ownership over your Contributions.
                      You retain full ownership of all of your Contributions and
                      any intellectual property rights or other proprietary
                      rights associated with your Contributions. We are not
                      liable for any statements or representations in your
                      Contributions provided by you in any area on the Services.
                      You are solely responsible for your Contributions to the
                      Services and you expressly agree to exonerate us from any
                      and all responsibility and to refrain from any legal
                      action against us regarding your Contributions.
                    </div>
                    <div>
                      We have the right, in our sole and absolute discretion:
                    </div>
                    <div className="flex flex-col gap-5 md:pl-5">
                      <div>
                        <b>(1)</b> To edit, redact, or otherwise change any
                        Contributions.
                      </div>
                      <div>
                        <b>(2)</b> To re-categorize any Contributions to place
                        them in more appropriate locations on the Services
                      </div>
                      <div>
                        <b>(3)</b> To pre-screen or delete any Contributions at
                        any time and for any reason, without notice. We have no
                        obligation to monitor your Contributions.
                      </div>
                    </div>
                  </div>
                </div>
                {/* 12. Mobile Application License */}
                <div
                  id="mobile-application-license"
                  className="flex flex-col gap-5"
                >
                  <div className="text-black">
                    <b>12. Mobile Application License</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <b>Use License</b>
                    </div>
                    <div>
                      If you access the Services via the App, then we grant you
                      a revocable, non-exclusive, non-transferable, limited
                      right to install and use the App on wireless electronic
                      devices owned or controlled by you, and to access and use
                      the App on such devices strictly in accordance with the
                      terms and conditions of this mobile application license
                      contained in these Legal Terms. You shall not:
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 md:pl-5">
                    <div>
                      <b>1.</b> Except as permitted by applicable law,
                      decompile, reverse engineer, disassemble, attempt to
                      derive the source code of, or decrypt the App.
                    </div>
                    <div>
                      <b>2.</b> Make any modification, adaptation, improvement,
                      enhancement, translation, or derivative work from the App.
                    </div>
                    <div>
                      <b>3.</b> Violate any applicable laws, rules, or
                      regulations in connection with your access or use of the
                      App.
                    </div>
                    <div>
                      <b>4.</b> Remove, alter, or obscure any proprietary notice
                      (including any notice of copyright or trademark) posted by
                      us or the licensors of the App.
                    </div>
                    <div>
                      <b>5.</b>  use the App for any revenue
                      generating endeavor, commercial enterprise, or other purpose for which
                      it is not designed or intended.
                    </div>
                    <div>
                      <b>6.</b> Make the App available over a network or other
                      environment permitting access or use by multiple devices
                      or users at the same time.
                    </div>
                    <div>
                      <b>7.</b> Use the App for creating a product, service, or
                      software that is, directly or indirectly, competitive with
                      or in any way a substitute for the App.
                    </div>
                    <div>
                      <b>8.</b> Use the App to send automated queries to any
                      website or to send any unsolicited commercial email.
                    </div>
                    <div>
                      <b>9.</b> Use any proprietary information or any of our
                      interfaces or our other intellectual property in the
                      design, development, manufacture, licensing, or
                      distribution of any applications, accessories, or devices
                      for use with the App.
                    </div>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <b>Apple and Android Devices</b>
                    </div>
                    <div>
                      The following terms apply when you use the App obtained
                      from either the Apple Store or Google Play (each an "App
                      Distributor") to access the Services:
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 md:pl-5">
                    <div>
                      <b>1.</b> The license granted to you for our App is limited to a non
                      transferable license to use the application on a device that utilizes the
                      Apple iOS or Android operating systems, as applicable, and in
                      accordance with the usage rules set forth in the applicable App
                      Distributors terms of service.
                    </div>
                    <div>
                      <b>2.</b> We are responsible for providing any maintenance
                      and support services with respect to the App as specified
                      in the terms and conditions of this mobile application
                      license contained in these Legal Terms or as otherwise
                      required under applicable law, and you acknowledge that
                      each App Distributor has no obligation whatsoever to
                      furnish any maintenance and support services with respect
                      to the App.
                    </div>
                    <div>
                      <b>3.</b> In the event of any failure of the App to
                      conform to any applicable warranty, you may notify the
                      applicable App Distributor, and the App Distributor, in
                      accordance with its terms and policies, may refund the
                      purchase price, if any, paid for the App, and to the
                      maximum extent permitted by applicable law, the App
                      Distributor will have no other warranty obligation
                      whatsoever with respect to the App.
                    </div>
                    <div>
                      <b>4.</b> You represent and warrant that (i) you are not
                      located in a country that is subject to a US government
                      embargo, or that has been designated by the US government
                      as a "terrorist supporting" country and (ii) you are not
                      listed on any US government list of prohibited or
                      restricted parties.
                    </div>
                    <div>
                      <b>5.</b> You must comply with applicable third-party
                      terms of agreement when using the App.
                    </div>
                    <div>
                      <b>6.</b> You acknowledge and agree that the App Distributors are third
                      party beneficiaries of the terms and conditions in this mobile
                      application license contained in these Legal Terms, and that each App
                      Distributor will have the right (and will be deemed to have accepted the
                      right) to enforce the terms and conditions in this mobile
                      application license contained in these Legal Terms against you as a
                      third-party beneficiary thereof.
                    </div>
                  </div>
                </div>
                {/* 13. Social Media */}
                <div id="social-media" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>13. Social Media</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      As part of the functionality of the Services, you may link
                      your account with online accounts you have with
                      third-party service providers (each such account, a
                      "Third-Party Account") by either: <br />
                      <div className="mb-5"></div>
                      <b>(1)</b> Providing your Third-Party Account login
                      information through the Services. <br />
                      <div className="mb-5"></div>
                      <b>(2)</b> Allowing us to access your Third-Party Account,
                      as is permitted under the applicable terms and conditions
                      that govern your use of each Third-Party Account.
                      <br />
                      <div className="mb-5"></div>
                      You represent and warrant that you are entitled to
                      disclose your Third-Party Account login information to us
                      and/or grant us access to your Third-Party Account,
                      without breach by you of any of the terms and conditions
                      that govern your use of the applicable Third-Party
                      Account, and without obligating us to pay any fees or
                      making us subject to any usage limitations imposed by the
                      third-party service provider of the Third-Party Account.
                      By granting us access to any Third-Party Accounts, you
                      understand that
                      <br />
                      <div className="mb-5"></div>
                      <b>(1)</b> We may access, make available, and store (if
                      applicable) any content that you have provided to and
                      stored in your Third-Party Account (the "Social Network
                      Content") so that it is available on and through the
                      Services via your account, including without limitation
                      any friend lists. <br />
                      <div className="mb-5"></div>
                      <b>(2)</b> We may submit to and receive from your
                      Third-Party Account additional information to the extent
                      you are notified when you link your account with the
                      Third-Party Account. <br />
                      <div className="mb-5"></div>
                      Depending on the Third-Party Accounts you choose and
                      subject to the privacy settings that you have set in such
                      Third-Party Accounts, personally identifiable information
                      that you post to your Third-Party Accounts may be
                      available on and through your account on the Services.
                      Please note that if a Third-Party Account or associated
                      service becomes unavailable or our access to such
                      Third-Party Account is terminated by the third-party
                      service provider, then Social Network Content may no
                      longer be available on and through the Services. You will
                      have the ability to disable the connection between your
                      account on the Services and your Third-Party Accounts at
                      any time. <br />
                      <div className="mb-5"></div>
                      PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY
                      SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
                      ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                      THIRD-PARTY SERVICE PROVIDERS. <br />
                      <div className="mb-5"></div>
                      We make no effort to review
                      any Social Network Content for any purpose, including but not limited to,
                      for accuracy, legality, or non-infringement, and we are not responsible for
                      any Social Network Content. You acknowledge and agree that we may
                      access your email address book associated with a Third-Party Account
                      and your contacts list stored on your mobile device or tablet computer
                      solely for purposes of identifying and informing you of those contacts
                      who have also registered to use the Services. You can deactivate the
                      connection between the Services and your Third-Party Account by
                      contacting us using the contact information below or through your
                      account settings (if applicable). We will attempt to delete any information
                      stored on our servers that was obtained through such Third
                      Party Account, except the username and profile picture that become
                      associated with your account.
                    </div>
                  </div>
                </div>
                {/* 14. Third-Party Websites and Content */}
                <div
                  id="third-party-websites-and-content"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>14. Third-Party Websites and Content</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      The Services may contain (or you may be sent via the Site or App) links
                      to other websites ("Third-Party Websites") as well as articles,
                      photographs, text, graphics, pictures, designs, music, sound, video,
                      information, applications, software, and other content or items belonging
                      to or originating from third parties ("Third-Party Content"). Such Third
                      Party Websites and Third-Party Content are not investigated, monitored,
                      or checked for accuracy, appropriateness, or completeness by us, and
                      we are not responsible for any Third-Party Websites accessed through
                      the Services or any Third-Party Content posted on, available through, or
                      installed from the Services, including the content, accuracy,
                      offensiveness, opinions, reliability, privacy practices, or other policies of
                      or contained in the Third-Party Websites or the Third-Party Content.
                      Inclusion of, linking to, or permitting the use or installation of any Third
                      Party Websites or any Third-Party Content does not imply approval or
                      endorsement thereof by us. If you decide to leave the Services and
                      access the Third-Party Websites or to use or install any Third
                      Party Content, you do so at your own risk, and you should be aware
                      these Legal Terms no longer govern. You should review the applicable
                      terms and policies, including privacy and data gathering practices, of any
                      website to which you navigate from the Services or relating to any
                      applications you use or install from the Services. Any purchases you
                      make through Third-Party Websites will be through other websites and
                      from other companies, and we take no responsibility whatsoever in
                      relation to such purchases which are exclusively between you and the
                      applicable third party. You agree and acknowledge that we do not
                      endorse the products or services offered on Third-Party Websites and
                      you shall hold us blameless from any harm caused by your purchase of
                      such products or services. Additionally, you shall hold us blameless from
                      any losses sustained by you or harm caused to you relating to or
                      resulting in any way from any Third-Party Content or any contact
                      with Third-Party Websites.
                    </div>
                  </div>
                </div>
                {/* 15. Advertisers */}
                <div id="advertisers" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>15. Advertisers</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      We allow advertisers to display their advertisements and
                      other information in certain areas of the Services, such
                      as sidebar advertisements or banner advertisements. We
                      simply provide the space to place such advertisements, and
                      we have no other relationship with advertisers.
                    </div>
                  </div>
                </div>
                {/* 16. Services Management */}
                <div id="services-management" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>16. Services Management</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <b>We reserve the right, but not the obligation to:</b>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 md:pl-5">
                    <div>
                      <b>(1)</b> Monitor the Services for violations of these
                      Legal Terms.
                    </div>
                    <div>
                      <b>(2)</b> Take appropriate legal action against anyone
                      who, in our sole discretion, violates the law or these
                      Legal Terms, including without limitation, reporting such
                      user to law enforcement authorities.
                    </div>
                    <div>
                      <b>(3)</b> In our sole discretion and without limitation,
                      refuse, restrict access to, limit the availability of, or
                      disable (to the extent technologically feasible) any of
                      your Contributions or any portion thereof.
                    </div>
                    <div>
                      <b>(4)</b> In our sole discretion and without limitation,
                      notice, or liability, to remove from the Services or
                      otherwise disable all files and content that are excessive
                      in size or are in any way burdensome to our systems.
                    </div>
                    <div>
                      <b>(5)</b> Otherwise manage the Services in a manner
                      designed to protect our rights and property and to
                      facilitate the proper functioning of the Services.
                    </div>
                  </div>
                </div>
                {/* 17. Privacy Policy */}
                <div id="privacy-policy" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>17. Privacy Policy</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      We care about data privacy and security. Please review our{" "}
                      <Link
                        className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                        to={"/privacy-policy"}
                      >
                        Privacy Policy
                      </Link>{" "}
                      By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the United States. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Services, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States. Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Services as quickly as is reasonably practical.
                    </div>
                  </div>
                </div>
                {/* 18. Copyright Infringements */}
                <div
                  id="copyright-infringements"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>18. Copyright Infringements</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      We respect the intellectual property rights of others. If
                      you believe that any material available on or through the
                      Services infringes upon any copyright you own or control,
                      please immediately notify us using the contact information
                      provided below (a "Notification"). A copy of your
                      Notification will be sent to the person who posted or
                      stored the material addressed in the Notification. Please
                      be advised that pursuant to applicable law you may be held
                      liable for damages if you make material misrepresentations
                      in a Notification. Thus, if you are not sure that material
                      located on or linked to by the Services infringes your
                      copyright, you should consider first contacting an
                      attorney.
                    </div>
                  </div>
                </div>
                {/* 19. Term and Termination */}
                <div id="term-and-termination" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>19. Term and Termination</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      These Legal Terms shall remain in full force and effect
                      while you use the Services. WITHOUT LIMITING ANY OTHER
                      PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO,
                      IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                      DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                      CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                      NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                      REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                      LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                      TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                      DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT
                      YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                      DISCRETION.
                    </div>
                    <div>
                      If we terminate or suspend your account for any reason,
                      you are prohibited from registering and creating a new
                      account under your name, a fake or borrowed name, or the
                      name of any third party, even if you may be acting on
                      behalf of the third party. In addition to terminating or
                      suspending your account, we reserve the right to take
                      appropriate legal action, including without limitation
                      pursuing civil, criminal, and injunctive redress.
                    </div>
                  </div>
                </div>
                {/* 20. Modifications and Interruptions */}
                <div
                  id="modifications-and-interruptions"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>20. Modifications and Interruptions</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      We reserve the right to change, modify, or remove the
                      contents of the Services at any time or for any reason at
                      our sole discretion without notice. However, we have no
                      obligation to update any information on our Services. We
                      will not be liable to you or any third party for any
                      modification, price change, suspension, or discontinuance
                      of the Services.
                    </div>
                    <div>
                      We cannot guarantee the Services will be available at all
                      times. We may experience hardware, software, or other
                      problems or need to perform maintenance related to the
                      Services, resulting in interruptions, delays, or errors.
                      We reserve the right to change, revise, update, suspend,
                      discontinue, or otherwise modify the Services at any time
                      or for any reason without notice to you. You agree that we
                      have no liability whatsoever for any loss, damage, or
                      inconvenience caused by your inability to access or use
                      the Services during any downtime or discontinuance of the
                      Services. Nothing in these Legal Terms will be construed
                      to obligate us to maintain and support the Services or to
                      supply any corrections, updates, or releases in connection
                      therewith.
                    </div>
                  </div>
                </div>
                {/* 21. Governing Law */}
                <div id="governing-law" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>21. Governing Law</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      These Legal Terms and your use of the Services are
                      governed by and construed in accordance with the laws of
                      the State of Florida applicable to agreements made and to
                      be entirely performed within the State of Florida, without
                      regard to its conflict of law principles.
                    </div>
                  </div>
                </div>
                {/* 22. Dispute Resolution */}
                <div id="dispute-resolution" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>22. Dispute Resolution</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <b>Informal Negotiations</b>
                    </div>
                    <div>
                      To expedite resolution and control the cost of any
                      dispute, controversy, or claim related to these Legal
                      Terms (each a "Dispute" and collectively, the "Disputes")
                      brought by either you or us (individually, a "Party" and
                      collectively, the "Parties"), the Parties agree to first
                      attempt to negotiate any Dispute (except those Disputes
                      expressly provided below) informally for at least thirty
                      (30) days before initiating arbitration. Such informal
                      negotiations commence upon written notice from one Party
                      to the other Party.
                    </div>
                    <div>
                      <b>Binding Arbitration</b>
                    </div>
                    <div>
                      If the Parties are unable to resolve a Dispute through
                      informal negotiations, the Dispute (except those Disputes
                      expressly excluded below) will be finally and exclusively
                      resolved by binding arbitration. YOU UNDERSTAND THAT
                      WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN
                      COURT AND HAVE A JURY TRIAL. The arbitration shall be
                      commenced and conducted under the Commercial Arbitration
                      Rules of the American Arbitration Association ("AAA") and,
                      where appropriate, the AAA’s Supplementary Procedures for
                      Consumer Related Disputes ("AAA Consumer Rules"), both of
                      which are available at the{" "}
                      <Link
                        className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                        to={"http://www.adr.org"}
                        target="_blank"
                      >
                        American Arbitration Association (AAA).
                      </Link>
                      Your arbitration fees and your share of arbitrator
                      compensation shall be governed by the AAA Consumer Rules
                      and, where appropriate, limited by the AAA Consumer Rules.
                      The arbitration may be conducted in person, through the
                      submission of documents, by phone, or online. The
                      arbitrator will make a decision in writing, but need not
                      provide a statement of reasons unless requested by either
                      Party. The arbitrator must follow applicable law, and any
                      award may be challenged if the arbitrator fails to do so.
                      Except where otherwise required by the applicable AAA
                      rules or applicable law, the arbitration will take place
                      in Palm Beach County, Florida. Except as otherwise
                      provided herein, the Parties may litigate in court to
                      compel arbitration, stay proceedings pending arbitration,
                      or to confirm, modify, vacate, or enter judgment on the
                      award entered by the arbitrator.
                    </div>
                    <div>
                      If for any reason, a Dispute proceeds in court rather than
                      arbitration, the Dispute shall be commenced or prosecuted
                      in the state and federal courts located in Palm Beach
                      County, Florida, and the Parties hereby consent to, and
                      waive all defenses of lack of personal jurisdiction, and
                      forum non conveniens with respect to venue and
                      jurisdiction in such state and federal courts. Application
                      of the United Nations Convention on Contracts for the
                      International Sale of Goods and the Uniform Computer
                      Information Transaction Act (UCITA) are excluded from
                      these Legal Terms.
                    </div>
                    <div>
                      In no event shall any Dispute brought by either Party related in any
                      way to the Services be commenced more than one (1) years after the
                      cause of action arose. If this provision is found to be illegal or
                      unenforceable, then neither Party will elect to arbitrate any Dispute
                      falling within that portion of this provision found to be illegal or
                      unenforceable and such Dispute shall be decided by a court of
                      competent jurisdiction within the courts listed for jurisdiction above,
                      and the Parties agree to submit to the personal jurisdiction of that
                      court.
                    </div>
                    <div>
                      <b>Restrictions</b>
                    </div>
                    <div className="flex flex-col gap-5">
                      The Parties agree that any arbitration shall be limited to
                      the Dispute between the Parties individually. To the full
                      extent permitted by law:
                      <div className="flex flex-col gap-5 md:pl-5">
                        <div>
                          <b>(a)</b> No arbitration shall be joined with any
                          other proceeding.
                        </div>
                        <div>
                          <b>(b)</b> There is no right or authority for any
                          Dispute to be arbitrated on a class-action basis or to
                          utilize class action procedures.
                        </div>
                        <div>
                          <b>(c)</b> There is no right or authority for any
                          Dispute to be brought in a purported representative
                          capacity on behalf of the general public or any other
                          persons.
                        </div>
                      </div>
                    </div>
                    <div>
                      <b>Exceptions to Informal Negotiations and Arbitration</b>
                    </div>
                    <div className="flex flex-col gap-5">
                      The Parties agree that the following Disputes are not
                      subject to the above provisions concerning informal
                      negotiations and binding arbitration:
                      <div className="flex flex-col gap-5 md:pl-5">
                        <div>
                          <b>(a)</b> Any Disputes seeking to enforce or protect,
                          or concerning the validity of, any of the intellectual
                          property rights of a Party.
                        </div>
                        <div>
                          <b>(b)</b> Any Dispute related to, or arising from,
                          allegations of theft, piracy, invasion of privacy, or
                          unauthorized use.
                        </div>
                        <div>
                          <b>(c)</b> Any claim for injunctive relief. If this
                          provision is found to be illegal or unenforceable,
                          then neither Party will elect to arbitrate any Dispute
                          falling within that portion of this provision found to
                          be illegal or unenforceable and such Dispute shall be
                          decided by a court of competent jurisdiction within
                          the courts listed for jurisdiction above, and the
                          Parties agree to submit to the personal jurisdiction
                          of that court.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 23. Corrections */}
                <div id="corrections" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>23. Corrections</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      There may be information on the Services that contains
                      typographical errors, inaccuracies, or omissions,
                      including descriptions, pricing, availability, and various
                      other information. We reserve the right to correct any
                      errors, inaccuracies, or omissions and to change or update
                      the information on the Services at any time, without prior
                      notice.
                    </div>
                  </div>
                </div>
                {/* 24. Disclaimer */}
                <div id="disclaimer" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>24. Disclaimer</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                      BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
                      YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                      DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                      WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                      LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                      MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY
                      OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
                      ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES
                      AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY:
                    </div>
                    <div className="flex flex-col gap-5 md:pl-5">
                      <div>
                        <b>(1)</b> ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
                        AND MATERIALS.
                      </div>
                      <div>
                        <b>(2)</b> PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                        NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
                        OF THE SERVICES.
                      </div>
                      <div>
                        <b>(3)</b> ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
                        SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                        AND/OR FINANCIAL INFORMATION STORED THEREIN.
                      </div>
                      <div>
                        <b>(4)</b> ANY INTERRUPTION OR CESSATION OF TRANSMISSION
                        TO OR FROM THE SERVICES.
                      </div>
                      <div>
                        <b>(5)</b> ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                        WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY
                        ANY THIRD PARTY.
                      </div>
                      <div>
                        <b>(6)</b> ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
                        MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
                        AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                        TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
                        SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
                        ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
                        SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
                        MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
                        ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
                        BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
                        YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
                        SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                        THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
                        YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                        APPROPRIATE.
                      </div>
                    </div>
                  </div>
                </div>
                {/* 25. Limitations of Liability */}
                <div
                  id="limitations-of-liability"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>25. Limitations of Liability</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                      BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                      INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                      OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
                      LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF
                      THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO
                      THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR
                      ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                      ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID,
                      IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR
                      TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND
                      INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                      WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                      DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                      ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                      YOU MAY HAVE ADDITIONAL RIGHTS.
                    </div>
                  </div>
                </div>
                {/* 26. Indemnification */}
                <div id="indemnification" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>26. Indemnification</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      You agree to defend, indemnify, and hold us harmless,
                      including our subsidiaries, affiliates, and all of our
                      respective officers, agents, partners, and employees, from
                      and against any loss, damage, liability, claim, or demand,
                      including reasonable attorneys’ fees and expenses, made by
                      any third party due to or arising out of:
                    </div>
                    <div className="flex flex-col gap-5 md:pl-5">
                      <div>
                        <b>(1)</b> Your Contributions.
                      </div>
                      <div>
                        <b>(2)</b> Use of the Services.
                      </div>
                      <div>
                        <b>(3)</b> Breach of these Legal Terms.
                      </div>
                      <div>
                        <b>(4)</b> Any breach of your representations and
                        warranties set forth in these Legal Terms.
                      </div>
                      <div>
                        <b>(5)</b> Your violation of the rights of a third
                        party, including but not limited to intellectual
                        property rights.
                      </div>
                      <div>
                        <b>(6)</b> Any overt harmful act toward any other user
                        of the Services with whom you connected via the
                        Services. Notwithstanding the foregoing, we reserve the
                        right, at your expense, to assume the exclusive defense
                        and control of any matter for which you are required to
                        indemnify us, and you agree to cooperate, at your
                        expense, with our defense of such claims. We will use
                        reasonable efforts to notify you of any such claim,
                        action, or proceeding which is subject to this
                        indemnification upon becoming aware of it.
                      </div>
                    </div>
                  </div>
                </div>
                {/* 27. User Data */}
                <div id="user-data" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>27. User Data</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      We will maintain certain data that you transmit to the
                      Services for the purpose of managing the performance of
                      the Services, as well as data relating to your use of the
                      Services. Although we perform regular routine backups of
                      data, you are solely responsible for all data that you
                      transmit or that relates to any activity you have
                      undertaken using the Services. You agree that we shall
                      have no liability to you for any loss or corruption of any
                      such data, and you hereby waive any right of action
                      against us arising from any such loss or corruption of
                      such data.
                    </div>
                  </div>
                </div>
                {/* 28. Electronic Communications, Transactions, and Signatures */}
                <div
                  id="electronic-communications-transactions-and-signatures"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>
                      28. Electronic Communications, Transactions, and
                      Signatures
                    </b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      Visiting the Services, sending us emails, and completing
                      online forms constitute electronic communications. You
                      consent to receive electronic communications, and you
                      agree that all agreements, notices, disclosures, and other
                      communications we provide to you electronically, via email
                      and on the Services, satisfy any legal requirement that
                      such communication be in writing. YOU HEREBY AGREE TO THE
                      USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
                      RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
                      AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
                      OR VIA THE SERVICES. You hereby waive any rights or
                      requirements under any statutes, regulations, rules,
                      ordinances, or other laws in any jurisdiction which
                      require an original signature or delivery or retention of
                      non-electronic records, or to payments or the granting of
                      credits by any means other than electronic means.
                    </div>
                  </div>
                </div>
                {/* 29. California Users and Residents */}
                <div
                  id="california-users-and-residents"
                  className="flex flex-col gap-2"
                >
                  <div className="text-black">
                    <b>29. California Users and Residents</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      If any complaint with us is not satisfactorily resolved,
                      you can contact the Complaint Assistance Unit of the
                      Division of Consumer Services of the California Department
                      of Consumer Affairs in writing at{" "}
                      <code>
                        1625 North Market Blvd., Suite N 112, Sacramento,
                        California 95834
                      </code>{" "}
                      or by telephone at{" "}
                      <Link
                        to="tel:(800) 952-5210"
                        className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                      >
                        (800) 952-5210
                      </Link>{" "}
                      or{" "}
                      <Link
                        to="tel:(916) 445-1254"
                        className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                      >
                        (916) 445-1254
                      </Link>
                    </div>
                  </div>
                </div>
                {/* 30. Miscellaneous */}
                <div id="miscellaneous" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>30. Miscellaneous</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      These Legal Terms and any policies or operating rules posted by us on
                      the Services or in respect to the Services constitute the entire agreement
                      and understanding between you and us. Our failure to exercise or
                      enforce any right or provision of these Legal Terms shall not operate as
                      a waiver of such right or provision. These Legal Terms operate to the
                      fullest extent permissible by law. We may assign any or all of our rights
                      and obligations to others at any time. We shall not be responsible or
                      liable for any loss, damage, delay, or failure to act caused by any cause
                      beyond our reasonable control. If any provision or part of a provision of
                      these Legal Terms is determined to be unlawful, void, or unenforceable,
                      that provision or part of the provision is deemed severable from these
                      Legal Terms and does not affect the validity and enforceability of any
                      remaining provisions. There is no joint venture, partnership, employment
                      or agency relationship created between you and us as a result of these
                      Legal Terms or use of the Services. You agree that these Legal Terms
                      will not be construed against us by virtue of having drafted them. You
                      hereby waive any and all defenses you may have based on the
                      electronic form of these Legal Terms and the lack of signing by the
                      parties hereto to execute these Legal Terms.
                    </div>
                  </div>
                </div>
                {/* 31. Contact Us */}
                <div id="contact-us" className="flex flex-col gap-2">
                  <div className="text-black">
                    <b>31. Contact Us</b>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      In order to resolve a complaint regarding the Services or
                      to receive further information regarding use of the
                      Services, please contact us at:
                    </div>
                    <div className="flex flex-col gap-2">
                      <div>Paw Play Love, LLC</div>
                      <address>
                        <Link
                          className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                          to={"https://maps.app.goo.gl/Xq7xqhMzy1udmAcu6"}
                        >
                          10640 Pebble Cove Lane <br />
                          Boca Raton, FL 33498 <br />
                          United States
                        </Link>
                      </address>
                      <div>
                        <Link
                          className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                          to={"mailto:info@pawplaylove.com"}
                        >
                          info@pawplaylove.com
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TermsNConditionSection;
