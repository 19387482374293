import React, { useState } from "react";

// Files Import
import Button from "../Button/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GradientLayout from "../Gradients/GradientLayout/GradientLayout";

export default function DeleteAccount() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    message: "",
  });

  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    setIsSending(true);
    toast.success("Request submitted successfully!");
    setIsSending(false);
    setFormData({
      email: "",
      password: "",
      message: "",
    });
  };

  return (
    <GradientLayout>
      <div className="flex flex-col w-[100%] md:w-[80%] gap-6 py-6">
        <div className="flex flex-col gap-2">
          <p className="text-xl font-bold text-black">Fill in the form</p>
          <hr />
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-4 w-[100%]">
          <div className="flex flex-col w-full md:w-[48%] gap-1">
            <input
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="flex p-3 rounded-md shadow-lg focus:outline-none"
            />
          </div>
          <div className="flex flex-col w-full md:w-[48%] gap-1">
            <input
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="flex p-3 rounded-md shadow-lg focus:outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4">
          <textarea
            rows="10"
            placeholder="Provide reason..."
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="flex p-3 rounded-md shadow-lg focus:outline-none"
            maxLength={500}
          />
        </div>
        <div className="flex self-center">
          <Button
            isDisabled={formData.email === "" || formData.password === "" || formData.message === ""}
            text={`${isSending ? " Requesting..." : "Request"}`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </GradientLayout>
  );
}
