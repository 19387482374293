import React from "react";

// Files Import
import AppStore from "../../../components/AppStore/AppStore";
import StyledH1Heading from "../../../common/components/StyledH1Heading/StyledH1Heading";
import StyledH4Heading from "../../../common/components/StyledH4Heading/StyledH4Heading";
import StyledH5Heading from "../../../common/components/StyledH5Heading/StyledH5Heading";

const HeroSection = () => {
  const styles = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: "url('/assets/hero-section/hero-bg.png')",
  };

  return (
    <div style={styles} className="flex flex-col justify-between lg:flex-row">
      <div className="pt-10 sm:pt-0 p-6 sm:p-0 sm:my-[10%] sm:ml-[10%] lg:w-4/5 xl:w-2/5 font-extrabold flex flex-col gap-10">
        <div>
          <StyledH4Heading
            fontColor={"text-black"}
            content={"Discover Love At First Woof."}
          />
          <StyledH1Heading
            fontColor={"text-black"}
            content={"Your Pet's Match Is A Swipe Away"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <StyledH5Heading
            fontColor={"text-black"}
            content={"Download our app"}
          />
          <AppStore />
        </div>
      </div>
      <div className="flex items-end justify-end">
        <img src="/assets/hero-section/hero-pets.png" alt="Pets" />
      </div>
    </div>
  );
};

export default HeroSection;
