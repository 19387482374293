import React, { useEffect, useState } from "react";

// Files Import
import { content } from "../../data";
import Button from "../Button/Button";
import MobileNav from "../MobileNav/MobileNav";
import { Link, NavLink } from "react-router-dom";
import { usePath } from "../../context/PathContext";
import { getOS } from "../../helpers";

export default function Header() {
  const { path } = usePath();
  const header = content.menuItems.header;

  const [os, setOS] = useState("Unknown OS");

  useEffect(() => {
    setOS(getOS());
  }, []);

  return (
    <div className="shadow-md py-[10px]">
      <div className="flex justify-between w-[90%] md:w-5/6 lg:w-4/5 mx-auto items-center">
        {/* LOGO */}
        <div>
          <Link to={"/"} className="cursor-pointer">
            <img src="/assets/petswipe-logo.svg" alt="Paw Play Love Logo" />
          </Link>
        </div>

        {/* NavLinks */}
        <div className="hidden md:flex">
          <ul className="flex md:gap-4 lg:gap-10">
            {header.map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={`text-black cursor-pointer font-semibold text-md hover:text-purple ${path === item.link ? "text-purple" : ""
                  }`}
              >
                {item.menu}
              </NavLink>
            ))}
          </ul>
        </div>

        {/* Mobile-Nav */}
        <div className=" md:hidden">
          <MobileNav />
        </div>

        {/* Header-Button */}
        <div className="hidden md:flex">
          <Link target="_blank" to={os === "MacOS" || os === "iOS" ? "https://apps.apple.com/us/app/paw-play-love/id6648773545" : "https://play.google.com/store/apps/details?id=com.pawplaylove"}>
            <Button text="Download app" />
          </Link>
        </div>
      </div>
    </div>
  );
}
