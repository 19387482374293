export const getOS = () => {
    const { userAgent } = window.navigator;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    if (/Macintosh/i.test(userAgent) && 'ontouchend' in document) {
        return "iOS";
    }
    if (/mac/i.test(userAgent)) {
        return "MacOS";
    }
    if (/windows/i.test(userAgent)) {
        return "Windows";
    }
    if (/linux/i.test(userAgent)) {
        return "Linux";
    }
    return "Unknown OS";
};
