import React from "react";

// Files Import
import StyledH2Heading from "../../common/components/StyledH2Heading/StyledH2Heading";
import SupportGradient from "../../components/Gradients/SupportGradient/SupportGradient";
import DeleteAccount from "../../components/DeleteAccount/DeleteAccount";

export default function DeleteAccountSection() {
  return (
    <div className="flex flex-col gap-5 py-10 mx-auto">
      <SupportGradient>
        <div className="flex flex-col sm:flex-row justify-between items-center w-[85%] mx-auto py-10 gap-6">
          <div className="sm:w-7/12 md:w-3/6">
            <StyledH2Heading fontColor={"text-black"} content={"Delete Account"} />
            <p className="text-darkGrey">
            Fill in the form below to remove your paw play love account.
            </p>
          </div>
          <div>
            <img src="/assets/support-pets.png" alt="Pets" />
          </div>
        </div>
      </SupportGradient>
      <div className="w-full">
        <DeleteAccount />
      </div>
    </div>
  );
}
