import React from "react";

const PawPlayLoveSection = () => {
  const styles = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: "url('/assets/paw-play-love-bg.png')",
  };
  return (
    <div
      style={styles}
      className="relative h-[100vh] 2xl:h-full flex justify-center md:justify-start items-center"
    >
      <div className="w-fit md:pl-20 lg:pl-24 xl:pl-[10%] 2xl:py-28 text-white font-extrabold uppercase text-[50px] sm:text-[60px] md:text-[9vw] lg:text-[8vw] 2xl:text-[90px] leading-snug">
        Paw.
        <br />
        Play.
        <br />
        Love.
      </div>
    </div>
  );
};

export default PawPlayLoveSection;
